export const conjuntives = [
    'na',
    'a',
    'A',
    'w',
    'o',
    '-',
    'i',
    'z',
    'Z',
    'W',
    'do',
    'by',
    'to',
    'lub',
    'nad',
    'od',
    'ale',
    'oraz',
    'że',
    'ze',
    'np.',
];

export const englishConjuntives = [
    'is',
    'a',
    'in',
    'of',
    'for',
    'if',
    'or',
    'to',
    'and',
    'on',
    'an',
    'the',
    'On',
];
