import { replaceSpacesInString } from 'droptheword';
import { conjuntives, englishConjuntives } from './conjuntives';

export const aboutUsOnePLOne = 'Twórcą Civileo Smart City jest firma ';
export const aboutUsOnePLTwo =
    ' z Gdańska. Osoby, które miały okazję poznać bliżej naszą firmę, wiedzą, że oprócz specjalizacji w tworzeniu oprogramowania na platformy telewizyjne, ';
export const aboutUsOnePLThree =
    ' rozwija również własne projekty w obszarze R&D. Prace nad innowacyjnymi pomysłami prowadzimy już od 2010 roku. Jesteśmy świadomi jak ważną rolę odgrywają działania w zakresie badań i rozwoju - zarówno dla społeczeństwa i postępu technologicznego, ale również dla pracowników, którzy mają okazję stawiać czoła wyzwaniom zawodowym i łączyć pracę z pasją.';
export const aboutUsTwoPLOne =
    'Po raz kolejny przekonaliśmy się, że idee oraz koncepcje opracowywane w ';
export const aboutUsTwoPLTwo =
    ' tworzą wartościowe przedsięwzięcia. Latem 2019 roku uzyskaliśmy dofinansowanie na projekt Civileo Smart City, podczas realizacji którego będziemy wykorzystywać i rozwijać naszą wieloletnią wiedzę w zakresie analizy obrazu i dźwięku. System będziemy stale rozwijać, dążąc do stworzenia takiego produktu, który będzie oferował szereg funkcjonalności zarówno mniejszym gminom, jak i dużym miastom.';
export const aboutUsOneENOne = 'The contractor for Civileo Smart City is ';
export const aboutUsOneEnTwo =
    ' from Gdańsk. Those who have had the chance to learn more about our company know that in addition to being specialised in creating software for TV platforms, ';
export const aboutUsOneEnThree =
    ' also develops its own projects in R&D. We have been working on innovative ideas since 2010, when we received funding from the European Union for the first time. We are aware of the major role played by research and development activities - both for society and technological progress, but also for employees who have the opportunity to face professional challenges and pair their work with passion.';
export const aboutUsTwoENOne =
    'Once again, we have found that the ideas and concepts developed at ';
export const aboutUsTwoENTwo =
    ' create valuable ventures. In the middle of the year 2019 we obtained funding for Civileo Smart City System, in which we use and expand our many years of know-how in the field of image and sound analysis. We will continue to develop the system further, striving to create such a product that will offer a number of functionalities to both smaller municipalities and large cities.';
export const aboutUsTextOnePLOne = replaceSpacesInString({
    text: aboutUsOnePLOne,
    dropWordList: conjuntives,
});
export const aboutUsTextOnePLTwo = replaceSpacesInString({
    text: aboutUsOnePLTwo,
    dropWordList: conjuntives,
});
export const aboutUsTextOnePLThree = replaceSpacesInString({
    text: aboutUsOnePLThree,
    dropWordList: conjuntives,
});
export const aboutUsTextOneENOne = replaceSpacesInString({
    text: aboutUsOneENOne,
    dropWordList: englishConjuntives,
});
export const aboutUsTextOneENTwo = replaceSpacesInString({
    text: aboutUsOneEnTwo,
    dropWordList: englishConjuntives,
});
export const aboutUsTextOneENThree = replaceSpacesInString({
    text: aboutUsOneEnThree,
    dropWordList: englishConjuntives,
});
export const aboutUsTextTwoPLOne = replaceSpacesInString({
    text: aboutUsTwoPLOne,
    dropWordList: conjuntives,
});
export const aboutUsTextTwoPLTwo = replaceSpacesInString({
    text: aboutUsTwoPLTwo,
    dropWordList: conjuntives,
});
export const aboutUsTextTwoENOne = replaceSpacesInString({
    text: aboutUsTwoENOne,
    dropWordList: englishConjuntives,
});
export const aboutUsTextTwoENTwo = replaceSpacesInString({
    text: aboutUsTwoENTwo,
    dropWordList: englishConjuntives,
});
/////////////////////////////////////////////////////
export const recomendationsHeaderPL = 'WSPÓŁPRACA';
export const recomendationsHeaderEN = 'COOPERATION';
export const recomendationsText =
    'Podpisując listy intencyjne z wyżej wymienionym gminami i miastami, rozpoczęliśmy rozmowy na temat współpracy dotyczącej rozwoju systemu Civileo. Jeśli podoba Ci się nasza idea i chciałbyś wdrożyć system w swojej miejscowości – ';
export const recomendationsTextEng =
    'By signing letters of intent with the aforementioned municipalities and cities, we started talks on cooperation regarding the development of the Civileo system. If you like our idea and would like to implement the system in your city - ';
export const recomendationsTextPL = replaceSpacesInString({
    text: recomendationsText,
    dropWordList: conjuntives,
});
export const recomendationsTextEN = replaceSpacesInString({
    text: recomendationsTextEng,
    dropWordList: englishConjuntives,
});
///////////////////////////////////////////////////////////////////////////////
export const dropInCrimePL = 'SPADEK PRZESTĘPCZOŚCI:';
export const dropInCrimeEN = 'DROP IN CRIME:';
export const katowiceExperiencePL = 'DOŚWIADCZENIE Z KATOWIC';
export const katowiceExperienceEN = 'KATOWICE CITY EXPERIENCE';
export const smartCityText =
    'Naszą misją jest wspieranie gmin w Polsce i na świecie w tworzeniu bezpiecznej oraz nowoczesnej przestrzeni publicznej poprzez dostarczanie najnowszych technologii w zakresie analizy obrazu i dźwięku.';
export const smartCityTextOnePartOne =
    'Pojęcie smart city rozumiemy jako koncepcję rozwoju poprzez zastosowanie nowych technologii w miejscach publicznych, mających na celu usprawnienie i ułatwienie życia mieszkańcom, a także zwiększenie bezpieczeństwa na terenie miasta.';
export const smartCityTextOnePartTwo =
    'Naszą misją jest wspieranie gmin oraz miast w Polsce i na świecie w tworzeniu inteligentnej i nowoczesnej przestrzeni publicznej poprzez dostarczanie narzędzi umożliwiających analizę danych w czasie rzeczywistym.';
export const smartCityTextTwoPartOne =
    'Jednym z głównych wyznaczników sukcesu wdrożenia inteligentnego monitoringu w Katowicach jest znaczny spadek przestępczości.';
export const smartCityTextTwoPartTwo =
    'Zaczynając pracę nad system Civileo, nawiązaliśmy współprace z Marcinem Palka, który jest odpowiedzialny za projekt, nadzór wdrożenia oraz dalszą rozbudowę Katowickiego Systemu Monitoringu i Analizy (KISMiA). To pierwszy w Polsce inteligentny system monitoringu, oparty o analizę obrazu z kamer miejskich. Wdrożenie systemu nastąpiło w 2017 roku, a Katowice mogły od tamtej pory zaobserwować znaczną poprawę funkcjonowania miasta. Korzystamy z doświadczenia Katowic, gdyż KISMiA w dużej mierze przypomina system Civileo w zakresie automatycznego monitoringu.';
export const smartCityTextOnePartOneEN =
    'We understand the term smart city as a concept of growth through the use of new technologies in public spaces, aimed at bettering and making life easier for residents, as well as increasing security in the city. ';
export const smartCityTextOnePartTwoEN =
    'We make it our mission to support communes and cities in Poland and in the world in creating a smart and modern public space through providing tools enabling the analysis of data in real time.';
export const smartCityTextTwoPartOneEN =
    'One of the main indicators of success in implementing smart monitoring in Katowice is the major drop in crime.';
export const smartCityTextTwoPartTwoEN =
    "Starting work on the Civileo system, we established cooperation with Marcin Palka, who is responsible for the implementation and development of the Katowice Monitoring and Analysis System (KISMiA). It’s the first in Poland smart monitoring system, based on analysis of images from city monitoring. The implementation of the system was carried out in 2017, and since then Katowice has seen a dramatic improvement in the city's functioning. We benefit from the experience of Katowice, since KISMiA largely resembles the Civileo system in the field of automatic monitoring.";
export const smartCityAboutTextOnePartOnePL = replaceSpacesInString({
    text: smartCityTextOnePartOne,
    dropWordList: conjuntives,
});
export const smartCityAboutTextOnePartTwoPL = replaceSpacesInString({
    text: smartCityTextOnePartTwo,
    dropWordList: conjuntives,
});
export const smartCityAboutTextTwoPartOnePL = replaceSpacesInString({
    text: smartCityTextTwoPartOne,
    dropWordList: conjuntives,
});
export const smartCityAboutTextTwoPartTwoPL = replaceSpacesInString({
    text: smartCityTextTwoPartTwo,
    dropWordList: conjuntives,
});
export const smartCityAboutTextOnePartOneEN = replaceSpacesInString({
    text: smartCityTextOnePartOneEN,
    dropWordList: englishConjuntives,
});
export const smartCityAboutTextOnePartTwoEN = replaceSpacesInString({
    text: smartCityTextOnePartTwoEN,
    dropWordList: englishConjuntives,
});
export const smartCityAboutTextTwoPartOneEN = replaceSpacesInString({
    text: smartCityTextTwoPartOneEN,
    dropWordList: englishConjuntives,
});
export const smartCityAboutTextTwoPartTwoEN = replaceSpacesInString({
    text: smartCityTextTwoPartTwoEN,
    dropWordList: englishConjuntives,
});
///////////////////////////////////////
export const systemTextPartOnePL =
    'System Civileo to inteligentne rozwiązanie dla miast oraz gmin. Jeśli na co dzień pracujesz w administracji publicznej i zajmujesz się poprawą funkcjonowania miasta lub zwiększeniem jakości życia mieszkańców, nasz System pozwoli Ci na ulepszenie wielu aspektów działania Twojej miejscowości. Z Civileo zyskasz również aplikację do komunikacji z mieszkańcami– ';
export const systemAboutTextPartOnePL = replaceSpacesInString({
    text: systemTextPartOnePL,
    dropWordList: conjuntives,
});
export const systemTextPartOneEN =
    'Civileo System is a smart solution for cities and communes. If you work in public administration and deal with improving the functioning of the city or enhancing the quality of residents’ life, our system will allow you to better many aspects of your city’s functioning. With Civileo, you also get an app for communicating with citizens– ';
export const systemAboutTextPartOneEN = replaceSpacesInString({
    text: systemTextPartOneEN,
    dropWordList: englishConjuntives,
});
export const systemTextPartTwoPL =
    ', by informować ich na bieżąco o najważniejszych sprawach w mieście.';
export const systemAboutTextPartTwoPL = replaceSpacesInString({
    text: systemTextPartTwoPL,
    dropWordList: conjuntives,
});
export const systemTextPartTwoEN =
    ', to keep them informed about major issues in the city.';
export const systemAboutTextPartTwoEN = replaceSpacesInString({
    text: systemTextPartTwoEN,
    dropWordList: englishConjuntives,
});
export const systemDescriptionOne =
    'Moduł Big Data pomoże gromadzić statystyki o zdarzeniach na terenie gminy oraz sporządzać na ich podstawie raporty. W ten sposób możliwe jest tworzenie np. map zagrożeń, które mogą znacznie ułatwić walkę z piratami drogowymi lub przestępczością.';
export const systemDescriptionOneEN =
    'The Big Data module will help collect statistics on events in the commune and prepare reports based on them. In this way, it is possible to create, e.g. risk maps which could significantly facilitate the battle with speeders and crime.';
export const systemDescriptionTwo =
    'Inteligentny monitoring pozwoli na automatyczne rozpoznawanie niebezpiecznych zdarzeń z wielu kamer i mikrofonów jednocześnie. Informacja o zdarzeniu natychmiastowo trafi do operatora monitoringu, który będzie mógł zawiadomić służby o wiele szybciej niż do tej pory. Dodatkowo informacje o niektórych zdarzeniach będą przesyłane mieszkańcom z danej okolicy by mogli odpowiednio szybko zareagować.';
export const systemDescriptionTwoEN =
    'Smart monitoring permits automatic recognition of threatening events from many camera and microphones simultaneously. Information about the event instantly reaches the monitoring operator, who can notify services much faster than before. On top of that, information about some events will be sent to cities of the given area, so they can react with according speed. ';
export const systemDescriptionThree =
    'Dzięki czujnikom zawartym w modułach montowanych na np. lampach ulicznych lub innych miejscach, mieszkańcy mogą dostawać informacje na bieżąco z ich najbliższej okolicy na temat warunków pogodowych, jakości powietrza,  czy też natężenia ruchu.';
export const systemDescriptionThreeEN =
    'Thanks to the sensors contained in modules mounted on street lamps or other areas, residents can obtain information from their immediate surroundings on weather conditions, air quality or traffic.';
export const systemDescriptionFour =
    'System Civileo zintegrowany jest z aplikacją Vileo, która użytkownikom posłuży do odbierania powiadomień z otoczenia, a także umożliwi zaufaną komunikację między najbliższymi sąsiadami i z urzędem.';
export const systemDescriptionFourEN =
    'Civileo System is integrated with the Vileo app, which will serve users to receive notifications from their surroundings, and also permit trusted communication between the closest neighbours and the office.';
export const systemDescriptionFive =
    'Civileo Smart City to system przeznaczony do aktywnego monitoringu miasta, działający zarówno na istniejących kamerach, jak i nowych inteligentnych lampach ulicznych LED, wyposażonych w kamerę oraz czujniki. Nasz system to kompleksowe narzędzie w budowaniu bezpiecznej przestrzeni publicznej.';
export const systemDescriptionFiveEN =
    'Civileo Smart City is a system designed for active city surveillance, operating both on existing cameras and new smart LED street lamps equipped with a camera and sensors. Our system is a complex tool in building a safe public space.';
export const descriptionBoxTextPL = replaceSpacesInString({
    text: systemDescriptionFive,
    dropWordList: conjuntives,
});
export const descriptionBoxTextEN = replaceSpacesInString({
    text: systemDescriptionFiveEN,
    dropWordList: englishConjuntives,
});
///////////////////////////////////////

export const vileoAbout =
    ' została stworzona z myślą o lokalnych społecznościach. Oprócz integracji z System Civileo, aplikacja działa jako komunikator dla mieszkańców poszukujących kontaktu z najbliższymi sąsiadami. Wydarzenia, usługi, polecenia – wszystko to w Twojej lokalizacji z osobami, które zostały zweryfikowane jako prawdziwe osoby zamieszkujące konkretny adres – dlatego komunikacja jest bezpieczna. Dodatkowo aplikacja posłuży ci jako cyfrowy punkt odbioru poczty– wszystkie dokumenty z urzędu, listy a nawet ulotki odczytasz w ';
export const vileoAboutTextPL = replaceSpacesInString({
    text: vileoAbout,
    dropWordList: conjuntives,
});
export const vileoAboutEN =
    ' was designed with local communities in mind. Beside integration with the Civileo System, the application will be able to act as a communicator for residents seeking contact with their closest neighbours. Events, services, recommendations - all this in your area with people who have been verified as real residents living under a specific address - which makes communication secure. On top of that, the application will serve you as a digital mail collection point - all documents from the office, letters and even leaflets can be read in ';
export const vileoAboutTextEN = replaceSpacesInString({
    text: vileoAboutEN,
    dropWordList: englishConjuntives,
});
//////////////////////////////////////////////////////////////////////////////////////////////
export const smartCityChartTextOnePL =
    'O skuteczności systemu KISMiA świadczy min. wykrywalność przestępstw ogółem, która od 2017 roku nie spada poniżej 65% oraz stale zmniejszająca się liczba stwierdzonych przestępstw w latach 2017 – 2019.';
export const smartCityChartTextOneEN =
    'Prove of KISMiA system effectiveness is crime detection in general which since 2017 is above 65% and also number of commited crimes based on 2017-2019 data was constantly decreasing.';
export const smartCityChartTextOneReplacedPL = replaceSpacesInString({
    text: smartCityChartTextOnePL,
    dropWordList: conjuntives,
});
export const smartCityChartTextOneReplacedEN = replaceSpacesInString({
    text: smartCityChartTextOneEN,
    dropWordList: englishConjuntives,
});
export const smartCityChartTextTwoPL =
    'Jednym z obszarów, gdzie efektywność systemu jest najbardziej widoczna jest min. kradzież pojazdów. W latach 2017 – 2019 współczynnik wykrycia wzrósł o niemal 33 punkty procentowe, przy jednoczesnym bardzo wyraźnym spadku kradzieży.';
export const smartCityChartTextTwoReplacedPL = replaceSpacesInString({
    text: smartCityChartTextTwoPL,
    dropWordList: conjuntives,
});
export const smartCityChartTextTwoEN =
    'One of the areas where system effetiveness is the highest is vehicle theft. In 2017-2019 crime detection ratio has increased by 33% whilst theft ratio significantly dropped.';
export const smartCityChartTextTwoReplacedEN = replaceSpacesInString({
    text: smartCityChartTextTwoEN,
    dropWordList: englishConjuntives,
});
export const smartCityChartTextThreePL =
    'Wskaźnik wykrycia przestępczości rozbójniczej ma szczególne znaczenie, ze względu na poczucie bezpieczeństwa mieszkańców miasta. Również w tym zakresie system KISMiA okazał się efektywnym narzędziem do przeciwdziałania czynom o takim charakterze.';
export const smartCityChartTextThreeReplacedPL = replaceSpacesInString({
    text: smartCityChartTextThreePL,
    dropWordList: conjuntives,
});
export const smartCityChartTextThreeEN =
    'Detection ratio of robbery crime has particular importance due to residents sense of security. In this field of crime activities, KISMiA system also proved to be an effective tool to prevent such an actions.';
export const smartCityChartTextThreeReplacedEN = replaceSpacesInString({
    text: smartCityChartTextThreeEN,
    dropWordList: englishConjuntives,
});
/////////////////////////////////////////////////
export const languageShortCuts = ['EN', 'PL'];
export const indexHeaderPL = {
    firstLinePL: 'Bezpieczne miasto',
    secondLinePL: 'Sieć sąsiedzka',
    thirdLinePL: 'Komunikacja z administracją',
};
export const indexHeaderEN = {
    firstLineEN: 'Safe City',
    secondLineEN: 'Neighbourly Network',
    thirdLineEN: 'Communication With Administration',
};
////////////////////////////////////////////////////////////////////////////////////////
